import * as React from "react";
import { graphql } from "gatsby";
import ComponentTemplatePage from "../../../../components/pages/templates/ComponentTemplatePage";

const ComponentTemplate = ({ data }) => (
  <ComponentTemplatePage
    title="Panel Components | UI Components Library | Uizard"
    category="Component Templates"
    url="/templates/component-templates/panel/"
    metaDescription="Uizard gives you the option to choose among three types of side panels. It's never been easier to design a panel on a web page mockup!"
    description="
    h2:A set of side panels to bring your web app designs forward
    <br/>
    Drag-and-drop panel component template to create mockups in minutes.
    <br/>
    Uizard helps you to build app and website designs faster and easier. Create mockups with panel component template in minutes. 
    <br/>
    h3:Choose among different types of side panels
    <br/>
    Uizard gives you the option to choose among three types of side panels (side panel with icons, email client side bar, and vertical nav side panel). It's never been easier to design a panel on a web page mockup, a web app prototype, or a mobile app UI concept!
    <br/>
    h3:The possibilities are endless
    <br/>
    With Uizard, you have a high level of customization of your panel templates. You can rearrange the structure, change the content, and adjust the styles to always reflect your chosen theme.
    <br/>
    Uizard is the ultimate app (or website) mockup tool that makes it easy to create stunning UI concepts and mockups.
    "
    pages={[
      "Side panel with icons",
      "Email client side bar",
      "Vertical nav side panel",
    ]}
    img1={data.image1.childImageSharp}
    img1alt="Screenshot showcasing the component template for designing image galleries"
    img2={data.image2.childImageSharp}
    img2alt="Screenshot showcasing the component template for designing image galleries"
    img3={data.image3.childImageSharp}
    img3alt="Screenshot showcasing the component template for designing image galleries"
    img4={data.image4.childImageSharp}
    img4alt="Screenshot showcasing the component template for designing image galleries"
    img5={data.image5.childImageSharp}
    img5alt="Screenshot showcasing the component template for designing image galleries"
  />
);

export default ComponentTemplate;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: { eq: "templates/component-templates/panel/panel_1.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: { eq: "templates/component-templates/panel/panel_2.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: { eq: "templates/component-templates/panel/panel_3.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: { eq: "templates/component-templates/panel/panel_4.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: { eq: "templates/component-templates/panel/panel_5.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
